body {
  margin: 0;
  font-family: 'Ubuntu', BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.border_bottom td {
  border-bottom: 1px solid #E0E0E0;
}

.react-json-view {
  word-break: break-all;
  text-align: left;
  }

.canvasjs-chart-toolbar-vertical {
  top: 0px !important;
  right: auto !important;
  left: 10px !important;
  transform : rotate(270deg) translate(-50%, -50%);
} 

.canvasjs-chart-tooltip-vertical {
  transform : rotate(270deg) translate(-50%, -50%);
} 

.MuiMultiSectionDigitalClock-root {
  ul {
    width: 80px !important
  }
}